<template>
  <div class="tiles section">
    <div class="composite-header">
      <h2>Live Auctions</h2>
      <router-link :to="artworksLink()">
        <span>View all</span>
        <div class="svg">
          <svg><use xlink:href="#svg-view-all" /></svg>
        </div>
      </router-link>
    </div>

    <div class="flex">
      <TokenCard v-for="(item, index) in items" :key="index" :token="item" />
    </div>
  </div>
</template>

<script>
import TokenCard from "@/components/common/TokenCard";
import useLinkMixin from "@/mixins/useLinkMixin";
export default {
  name: "LiveAuctionsSection",
  mixins: [useLinkMixin],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  components: { TokenCard },
};
</script>
