export default {
  data: () => ({
    innerWidth: window.innerWidth,
  }),
  methods: {
    checkResize() {
      window.addEventListener("resize", (e) => {
        this.innerWidth = e.target.innerWidth;
      });
    },
  },
  mounted() {
    this.checkResize();
  },
};
