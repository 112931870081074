<template>
  <div id="hot" class="section">
    <div class="flex">
      <SliderItem
        :is-large="true"
        :slide="slides[0].collection ? slides[0].collection : slides[0].token"
        :slide-type="slides[0].collection ? 'COLLECTION' : 'TOKEN'"
      />

      <div class="flex">
        <SliderItem
          v-for="(item, index) in slides.slice(1)"
          :slide="item.collection ? item.collection : item.token"
          :slide-type="item.collection ? 'COLLECTION' : 'TOKEN'"
          :key="index"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SliderItem from "@/components/slider/SliderItem";
export default {
  name: "Slider",
  components: { SliderItem },
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    if (this.slides.length !== 5) {
      for (let i = this.slides.length - 1; i < 5; i++) {
        this.slides.push({});
      }
    }
  },
};
</script>
