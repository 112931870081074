<template>
  <div class="tiles section">
    <div class="composite-header">
      <h2>Featured Creators</h2>
      <router-link :to="creatorsLink()">
        <span>View all</span>
        <div class="svg">
          <svg><use xlink:href="#svg-view-all" /></svg>
        </div>
      </router-link>
    </div>

    <div class="flex">
      <UserCard v-for="(item, index) in items" :user="item" :key="index" />
    </div>
  </div>
</template>

<script>
import UserCard from "@/components/common/UserCard";
import useLinkMixin from "@/mixins/useLinkMixin";
export default {
  name: "FeaturedCreatorsSection",
  mixins: [useLinkMixin],
  components: { UserCard },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
