<template>
  <div class="home-page">
    <Slider :slides="slides" v-if="slides.length" />
    <LiveAuctionsSection :items="auctions" v-if="auctions.length" />
    <FeaturedCollections :items="collections" v-if="collections.length" />
    <FeaturedArtworksSection :items="tokens" v-if="tokens.length" />
    <FeaturedCreatorsSection :items="creators" v-if="creators.length" />
  </div>
</template>

<script>
import LiveAuctionsSection from "@/components/home/LiveAuctionsSection";
import FeaturedCollections from "@/components/home/FeaturedCollectionsSection";
import FeaturedArtworksSection from "@/components/home/FeaturedArtworks";
import FeaturedCreatorsSection from "@/components/home/FeaturedCreatorsSection";
import api from "@/api/api";
import Slider from "@/components/slider/Slider";
import setLoadingMixin from "@/mixins/setLoadingMixin";
import resizeViewportMixin from "@/mixins/resizeViewportMixin";
export default {
  name: "Home",
  mixins: [setLoadingMixin, resizeViewportMixin],
  data: () => ({
    page: 0,
    size: 8,
    creators: [],
    tokens: [],
    collections: [],
    auctions: [],
    slides: [],
    range1: false,
    range2: false,
  }),
  watch: {
    async innerWidth(val) {
      if (val >= 2421 && !this.range2) {
        this.range2 = true;
        this.size = 12;
        await this.init();
      }
      if (val < 2421 && val > 1920 && this.range2) {
        this.range2 = false;
        this.range1 = true;
        this.size = 10;
        await this.init();
      }
      if (val >= 1921 && !this.range1) {
        this.range1 = true;
        this.size = 10;
        await this.init();
      }
      if (val < 1921 && val >= 1480) {
        this.range1 = false;
        this.range2 = false;
        this.size = 8;
        await this.init();
      }
      if (val < 1480) {
        this.range1 = false;
        this.range2 = false;
        this.size = 6;
        await this.init();
      }
    },
  },
  methods: {
    appointLoaderSize() {
      if (this.innerWidth < 1480) {
        this.size = 6;
      }
      if (this.innerWidth >= 1480) {
        this.size = 8;
      }
      if (this.innerWidth >= 1921) {
        this.size = 10;
        this.range1 = true;
      }
      if (this.innerWidth >= 2421) {
        this.size = 12;
        this.range2 = true;
        this.range1 = true;
      }
    },
    async getAllAdminObjects() {
      try {
        this.slides = await api.getAllAdminObjects();
        this.slides.sort(function (a, b) {
          if (a.position > b.position) {
            return 1;
          }
          if (a.position < b.position) {
            return -1;
          }
          return 0;
        });
      } catch {
        this.slides = [];
      }
    },
    async getMainPageLiveAuctionsTokens() {
      try {
        const response = await api.getMainPageLiveAuctionsTokens({
          page: 0,
          size: this.size,
        });
        this.auctions = response.content;
      } catch {
        this.auctions = [];
      }
    },
    async getMainPageNftCollections() {
      try {
        const response = await api.getMainPageNftCollections({
          page: this.page,
          size: this.size,
        });
        this.collections = response.content;
      } catch {
        this.collections = [];
      }
    },
    async getMainPageNftTokens() {
      try {
        const response = await api.getMainPageNftTokens({
          page: this.page,
          size: this.size,
        });
        this.tokens = response.content;
      } catch {
        this.tokens = [];
      }
    },
    async getMainPageCreators() {
      try {
        const response = await api.getMainPageCreators({
          page: this.page,
          size: this.size,
        });
        this.creators = response.content;
      } catch {
        this.creators = [];
      }
    },
    async init() {
      await Promise.all([
        this.getMainPageLiveAuctionsTokens(),
        this.getMainPageNftCollections(),
        this.getMainPageCreators(),
        this.getMainPageNftTokens(),
      ]);
    },
  },
  async mounted() {
    this.setLoading(true);
    this.appointLoaderSize();
    await Promise.all([this.getAllAdminObjects(), this.init()]);
    this.setLoading(false);
  },
  components: {
    Slider,
    FeaturedCreatorsSection,
    FeaturedArtworksSection,
    FeaturedCollections,
    LiveAuctionsSection,
  },
};
</script>
