<template>
  <div class="hot-tile" :class="{ main: isLarge }">
    <TokenContentType
      :wrapper="'preview'"
      :file-url="slideFileUrl"
      :token-type="slideFileType"
      :show-audio-player="false"
      :preview-url="slidePreviewUrl"
    />

    <div class="overlay">
      <router-link
        class="hot-link"
        :to="
          nftLink(
            slideBlockChainId || slideId,
            slideTokenCurrency,
            getContractAddressByCurrency(slideTokenCurrency, slideBlockChainId)
          )
        "
        v-if="isToken"
      />
      <router-link class="hot-link" :to="collectionLink(slideId)" v-else />
      <div class="hot-title">{{ truncate(slideTitle, 20) }}</div>
      <UsernameLink
        :wrap-class="'hot-username'"
        :username="userNickname"
        :user-id="userId"
        :user-address="userAddress"
      />
      <div
        class="hot-amount"
        v-if="isSlideTokenAuction && !isSlideTokenTimeAuction"
      >
        Price: {{ formatNumber(slideTokenPrice) }} {{ slideTokenCurrency }}
      </div>
      <div
        class="hot-amount"
        v-if="isSlideTokenAuction && isSlideTokenTimeAuction"
      >
        Last bid: {{ formatNumber(slideTokenPrice) }} {{ slideTokenCurrency }}
      </div>
      <Countdown
        v-if="isSlideTokenTimeAuction && !needRefreshCountdown"
        @finish="needRefresh"
        :end-time="slideTokenTimeAuctionEnd"
        :start-time="slideTokenTimeAuctionStart"
        :is-short="true"
      />
      <div class="hot-cols" v-if="tokenCollections.length <= 5">
        <span v-for="(item, index) in tokenCollections" :key="index">
          <router-link :to="collectionLink(item.id)" class="hot-col-img">
            <img :src="item.fileUrl" lazy="loaded" />
          </router-link>
          <span class="hot-col-tip">{{ item.title }}</span>
        </span>
      </div>
      <div class="hot-cols" v-else>More than 5 collections</div>
    </div>
  </div>
</template>

<script>
import UsernameLink from "@/components/common/UsernameLink";
import formatNumberMixin from "@/mixins/formatNumberMixin";
import Countdown from "@/components/common/Countdown";
import TokenContentType from "@/components/common/TokenContentType";
import truncateMixin from "@/mixins/truncateMixin";
import getContractAddressByCurrencyMixin from "@/mixins/getContractAddressByCurrencyMixin";
import refreshCountdownMixin from "../../mixins/refreshCountdownMixin";
import useLinkMixin from "@/mixins/useLinkMixin";
export default {
  name: "SliderItem",
  mixins: [
    formatNumberMixin,
    truncateMixin,
    getContractAddressByCurrencyMixin,
    refreshCountdownMixin,
    useLinkMixin,
  ],
  components: { TokenContentType, UsernameLink, Countdown },
  props: {
    isLarge: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    slideType: {
      type: String,
      required: true,
    },
    slide: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isToken() {
      return this.slideType === "TOKEN";
    },
    isSlideTokenCollection() {
      return (
        this.isToken && this.slide.collection && this.slide.collection.length
      );
    },
    isSlideTokenAuction() {
      return this.isToken && this.slide.activeAuction;
    },
    slideFileType() {
      return this.isToken ? this.slide.type : "IMAGE";
    },
    isSlideTokenTimeAuction() {
      return (
        this.isSlideTokenAuction &&
        this.slide.activeAuction.type === "TIME_AUCTION"
      );
    },
    slideBlockChainId() {
      return this.isToken ? this.slide.blockChainId : this.slide.id;
    },
    slideId() {
      return this.slide.id;
    },
    tokenCollections() {
      return this.isSlideTokenCollection ? this.slide.collection : "";
    },
    slideTitle() {
      return this.slide.title;
    },
    slideFileUrl() {
      return this.slide.fileUrl;
    },
    slidePreviewUrl() {
      return this.slide.previewUrl;
    },
    userId() {
      return this.isToken ? this.slide.creator.id : this.slide.owner.id;
    },
    userAddress() {
      return this.isToken
        ? this.slide.creator.address
        : this.slide.owner.address;
    },
    userNickname() {
      return this.isToken
        ? this.slide.creator.nickname
        : this.slide.owner.nickname;
    },
    slideTokenPrice() {
      return this.isSlideTokenAuction
        ? this.slide.activeAuction.minBidPrice
        : 0;
    },
    slideTokenCurrency() {
      return this.isToken ? this.slide.currency : "";
    },
    slideTokenTimeAuctionEnd() {
      return this.isSlideTokenTimeAuction
        ? this.slide.activeAuction.endTime
        : 0;
    },
    slideTokenTimeAuctionStart() {
      return this.isSlideTokenTimeAuction
        ? this.slide.activeAuction.startTime
        : 0;
    },
  },
};
</script>
